// src/templates/blog-post.js

import * as React from "react";
import { graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "../components/layout";
import Seo from "../components/seo";


/*
 * *** blog-post template  ***
 * ---------------------------
 * for pages in content/textpages
 */


const mdxComponents = {
  h1: (props) => <h1 className="pb-4 xl6Light">{props.children}</h1>,
  h2: (props) => (
    <h2 className="pt-16 pb-4 xl3SerifSemibold">{props.children}</h2>
  ),
  h3: (props) => <h3 className="pt-4 pb-0 font-semibold">{props.children}</h3>,
  p: (props) => <p className="pb-4 lg" {...props} />,
  ul: (props) => <ul className="px-6 lg list-interpunct" {...props} />,
};

const TextPageTemplate = ({ data, location }) => {
  const  page = data.mdx;
    return (
      <Layout location={location}>
        <Seo
          title={page.frontmatter.title}
          description={page.frontmatter.description || ""}
        />

        <main className="l-typeArea">
          <MDXProvider components={mdxComponents}>
            <MDXRenderer>{page.body}</MDXRenderer>
          </MDXProvider>
        </main>
      </Layout>
    );
};

export default TextPageTemplate;

export const pageQuery = graphql`
  query TextPage(
    $id: String!

  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        description
      }
    }
  }
`;
